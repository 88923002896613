import {Info} from 'app/styles/InfoText';
import React from 'react';
import {LinkOnComponent} from 'app/styles/link';
import {COLORS, SIZES} from '@fupa/fupa-uikit';
import {useLocation} from 'react-router';
import styled from 'styled-components';

const HintText = styled.span`
  margin-right: ${SIZES['2']};
`;

const AuthHint = ({text, linkedText, linkTo, className}) => {
  const {search} = useLocation();

  return (
    <Info className={className} variant='body1'>
      {text ? <HintText>{text}</HintText> : null}
      <LinkOnComponent tabIndex='2' color={COLORS.fupaSecondary} to={{pathname: linkTo, search}}>
        {linkedText}
      </LinkOnComponent>
    </Info>
  );
};

export {AuthHint};
